<template>
  <div :style="{ padding: '50px', margin: '10px 0', overflow: 'visibile' }" class="card">
    <div><b>updating via v-model</b></div>

    <DatePicker v-model="value1" ariaLabel="date" label="date" />

    <b-input v-model="inputValue" updater />
    <b-button @click="update" type="is-primary" updater>update</b-button>
  </div>
</template>

<script>
import DatePicker from './../index'

export default {
  components: {
    DatePicker
  },
  data() {
    return {
      value1: '',
      inputValue: '2309-01-13T00:03:20.419'
    }
  },
  methods: {
    update() {
      console.log('update called?')

      this.value1 = this.inputValue
    }
  }
}
</script>
