<template>
  <div :style="{ padding: '50px', margin: '10px 0', overflow: 'visibile' }" class="card">
    <div><b>with partial values</b></div>

    <DatePicker
      ref="datePickerComponent"
      ariaLabel="date"
      label="date"
      ariaPrefix="from"
      style="padding-right: 10px"
      :onUpdate="onUpdate"
      :startEmpty="true"
    />

    <div>result: {{ month }} {{ day }} {{ year }}</div>

    <br />

    <div>earliest possible: {{ from }}</div>
    <div>latest possible: {{ to }}</div>
  </div>
</template>

<script>
import DatePicker from './../index'

import { completeDate } from '@/utilities/Date/complete'

export default {
  components: {
    DatePicker
  },
  data() {
    return {
      month: '',
      day: '',
      year: '',
      from: '',
      to: ''
    }
  },
  methods: {
    onUpdate({ day, month, year }) {
      // const { day, month, year } = this.$refs.datePickerComponent.getValue ();

      this.day = day
      this.month = month
      this.year = year

      const { fromDate, toDate } = completeDate({
        from: { day, month, year },
        to: { day, month, year }
      })

      this.from = fromDate
      this.to = toDate
    }
  }
}
</script>
