<template>
  <div :style="{ padding: '100px' }">
    <b :style="{ padding: '20px 0', fontSize: '20px' }">Date Picker</b>

    <VModel />
    <Partial />
    <WithErrorMessage />
  </div>
</template>

<script>
//
import VModel from './VModel'
import Partial from './Partial'
import WithErrorMessage from './WithErrorMessage'
//
export default {
  components: {
    Partial,
    VModel,
    WithErrorMessage
  }
}
</script>
