<template>
  <div :style="{ padding: '50px', margin: '10px 0', overflow: 'visibile' }" class="card">
    <div><b>updating via v-model, with error message</b></div>

    <DatePicker
      ref="datePicker"
      v-model="value1"
      :errorMessage.sync="value2"
      ariaLabel="date"
      label="date"
    />

    <div :style="{ padding: '50px 0 0' }">
      <b-input v-model="errorValue" updater />
      <br />
      <b-button @click="updateError" type="is-primary" updater>update error message</b-button>
    </div>

    <div :style="{ padding: '50px 0 0' }">
      <b-input v-model="inputValue" updater />
      <br />
      <b-button @click="update" type="is-primary" updater>update</b-button>
    </div>
  </div>
</template>

<script>
import DatePicker from './../index'

export default {
  components: {
    DatePicker
  },
  data() {
    return {
      value1: '',
      value2: '',

      inputValue: '2309-01-13T00:03:20.419',
      errorValue: 'That date is not possible'
    }
  },
  methods: {
    update() {
      this.value1 = this.inputValue
    },
    updateError() {
      this.value2 = this.errorValue
      // this.$refs.datePicker.showErrorMessage ();
    }
  }
}
</script>
